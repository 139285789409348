<template>
    <a :href="'https://'+urlapu+'/informes_albaran.php'" target="_blank">Informes Albaran</a>
</template>
<script>

export default ({
    props: [],
  
    components: {
       
    },
    data() {

        return {
            urlapu: "",

            
        }
    },
    methods: {
       
    },
    watch: {
      
    },
    mounted() {
        this.urlapu = localStorage.getItem('direccion');

    },
})



</script>